$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

@import url(/css/gfonts.css);
$cs-white: #ffffff;
$cs-teal-lighter: #deeef9;
$cs-teal-light: #79c4e2;
$cs-teal: #42a7c6;
$cs-teal-dark: #3d94ab;
$cs-teal-darker: #38798f;
$cs-teal-darkest: #326074;
$cs-blue: #067ccd;
$cs-blue-light: #238bd4;
$cs-blue-lighter: #6da2ba;
$cs-brown: #483806;
$cs-grey-darkest: #363d49;
$cs-grey-darker: #444b58;
$cs-grey-dark: #3e4c51;
$cs-grey: #6c6f79;
$cs-grey-light: #d8dce3;
$cs-grey-lighter: #edf0f4;
$cs-grey-lightest: #f3f4f6;
$cs-grey-table-border: #d7dadf;
$cs-grey-user: #b8bcc4;
$cs-grey-logout: #9fa1a4;
$cs-red: #ff1839;
$cs-pink: #e279b7;
$cs-brown: #3f3001;
$cs-yellow: #ffda6a;
$cs-gold: #eeebc0;
$cs-black: #000;
$cs-green: #acf17f;
$cs-table-green: #c9f1a2;
$color_2: #111;
$color_4: #7d9fbb;
$color_5: #333;
$color_6: #444;
$color_7: #515151;
$color_8: #fff;
$color_9: #ccdae5;
$color_10: #ccd8e1;
$color_11: #999;
$cs-font: "museo-sans", sans-serif;
$background_color_1: #fafafa;
$background_color_3: #003057;
$background_color_4: rgba(0, 0, 0, 0.3);
$background_color_5: #003b6a;
$background_color_6: #00325a;
$background_color_7: #eee;
$background_color_8: #ef3e35;
$cs-transparent: transparent;
$background_color_10: 0;
$border_color_1: #c5c5c5;
$primary-btn-color: #3080df;
@import "/css/gfonts.css";

body {
  margin: 0;
  background-color: $cs-grey-lightest;
  color: $cs-grey-darkest;
  font-size: 13px;
  font-family: $cs-font;
  font-weight: 300;
  a {
    color: $cs-teal;
  }
  @media (max-width: 768px) {
    background-color: $cs-white;
  }
}

.classic {
  table {
    font-weight: normal;
    border-spacing: 1px;
    border-collapse: separate;
    td {
      padding: 2px;
    }
    a {
      text-decoration: underline;
    }
  }
}

table {
  font-weight: normal;
  border-spacing: 1px;
  td {
    padding: 2px;
  }
  a {
    text-decoration: underline;
  }
}

hr {
  border-bottom-width: 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: $cs-grey-light;
  border-right-color: $cs-grey-light;
  border-bottom-color: $cs-grey-light;
  border-left-color: $cs-grey-light;
  background-color: $cs-grey-light;
}

.container,
.container-fluid {
  hr {
    border-bottom-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #eeeeee;
    border-right-color: #eeeeee;
    border-bottom-color: #eeeeee;
    border-left-color: #eeeeee;
    background-color: #eeeeee;
  }
  table {
    font-weight: normal;
    border-collapse: collapse;
    border-spacing: 1px;
    td {
      padding: 2px;
    }
    a {
      text-decoration: none;
    }
  }
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: $cs-black;
  padding: 0.2em 0;
}

a {
  color: $cs-teal-dark;
  &:hover {
    color: $color_4;
  }
  &:focus {
    color: $color_4;
  }
}

h1 {
  font-size: 24px;
  color: $cs-grey-dark;
  line-height: 1em;
  margin: 0 0 0.65em;
  font-weight: lighter;
  padding-bottom: 20px;
  border-bottom: 1px solid $cs-grey-lighter;
}

h2 {
  color: $cs-teal;
  font-size: 18px;
  line-height: 1em;
  margin: 1.333em 0 0.722em;
}

h3 {
  color: $color_5;
  font-size: 16px;
  line-height: 1em;
  margin: 1.333em 0 0.813em;
}

h4 {
  font-size: 18px;
  color: $cs-grey-dark;
  line-height: 1em;
  margin: 1.333em 0 0.929em;
  font-weight: lighter;
}

h5 {
  font-size: 12px;
  line-height: 1em;
  margin: 1.333em 0 1.083em;
  font-size: 11px;
  line-height: 1em;
  margin: 1.333em 0 1.182em;
}

h6 {
  font-size: 10px;
  line-height: 1em;
  margin: 1.333em 0 1.3em;
}

.main-content {
  background-color: $cs-white;
  padding: 25px 15px;
}

.main-content-calendar {
  background-color: $cs-white;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  h1 {
    padding-bottom: 10px;
    padding-top: 5px;
    margin: 0;
  }
}

select {
  width: auto;
  border: 1px solid $cs-grey-light;
  background: $cs-white url("../images/cs-assets/cs-dropdown.svg") no-repeat;
  background-size: 10px;
  background-position: right 6px center;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

select::-ms-expand {
  visibility: hidden;
}

.select-container {
  select {
    display: inline-block;
    min-width: 75px;
    border: 1px solid $cs-grey-light;
    background: $cs-grey-lightest url("../images/cs-assets/cs-dropdown.svg")
      no-repeat;
    background-size: 10px;
    height: 35px;
    background-position: right 6px center;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: $cs-grey;
    padding-left: 10px;
    padding-right: 25px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
  }
  select:disabled {
    border: 1px solid $cs-grey-lightest;
    background: $cs-white url("../images/cs-assets/cs-dropdown.svg") no-repeat;
    color: $cs-grey-logout;
    background-size: 10px;
    padding-right: 25px;
    background-position: right 6px center;
  }
}

label {
  font-size: 16px;
  color: $cs-grey-dark;
  padding-right: 10px;
}

#content {
  select {
    padding-right: 22px;
  }
  table {
    display: table;
    border-collapse: separate;
    border-spacing: 4px;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  th {
    text-align: center;
  }
  #messages {
    display: table;
    border-collapse: collapse;
    border-spacing: 0px;
    td {
      padding: 4px;
      text-align: center;
      text-decoration: underline;
    }
  }
  #year-view {
    a {
      text-decoration: underline;
    }
  }
  #providers {
    display: table;
    border-collapse: collapse;
    border-spacing: 0px;
  }
  #day {
    display: table;
    border-collapse: collapse;
    border-spacing: 0px;
  }
}

#header {
  background-color: $cs-white;
  .inner {
    margin: 0 auto;
    padding: 0 20px;
  }
  .site-name {
    display: block;
    float: left;
    width: 214px;
    &.tc_physician_scheduling-logo {
      margin-top: 12px;
    }
    &.tc_resident_scheduling-logo {
      margin-top: 12px;
    }
  }
  .site-name.classic {
    img {
      height: 85px;
    }
  }
  .site-name.enterprise {
    img {
      height: 85px;
    }
  }
  #utility {
    float: right;
    text-align: right;
    .text-wrapper {
      display: inline-block;
      padding: 20px 0;
    }
    .utility-image {
      display: inline-block;
      img {
        height: 70px;
        margin-top: -50px;
        margin-left: 10px;
        margin-right: 10px;
      }
      #tigerconnect_avatar {
        width: 70px;
        height: 70px;
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        margin-top: -50px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .welcome {
      color: $cs-grey-darkest;
      font-size: 1.25em;
      line-height: 2em;
    }
    .logout {
      color: $cs-grey-logout;
      font-weight: 600;
      margin-top: 15px;
      line-height: 1.5em;
      text-decoration: none;
      img {
        height: 16px;
        margin-top: -2px;
        margin-left: 5px;
      }
    }
    .user {
      color: $cs-grey-logout;
      font-weight: 300;
    }
  }
}

.nav-color {
  background-color: $cs-teal;
  .help-wrapper {
    a {
      color: $cs-white;
      text-decoration: none;
      cursor: pointer;
    }

    float: right;
    height: 52px;
    padding-left: 20px;
    padding-right: 30px;
    border-left: solid 1px rgba(121, 196, 226, 0.45);
  }
  .help-icon {
    img {
      width: 9px;
      margin-top: 10px;
      margin-left: 8px;
      margin-bottom: 2px;
    }
  }
  a {
    color: $cs-white;
    text-decoration: none;
  }
}

.nav-main {
  background-color: $cs-teal;
  ul {
    list-style: none;
    margin: 0 auto;
    background: $cs-teal;
  }
  ul li {
    display: block;
    position: relative;
    float: left;
    background: $cs-teal;
  }
  li ul {
    display: none;
  }
  ul li a {
    display: block;
    padding: 0.9em 0.8em;
    text-decoration: none;
    white-space: nowrap;
    color: #fff;
  }
  ul li a:hover {
    background: $cs-teal-dark;
    font-weight: 300;
    padding: 0.9em 0.8em;
  }
  li:hover > ul {
    display: block;
    position: absolute;
    min-width: 200px;
  }
  ul li ul li {
    background: $cs-teal-dark;
  }
  li:hover li {
    float: none;
  }
  li:hover > a {
    background: $cs-teal-dark;
  }
  li:hover li a:hover {
    background: $cs-teal-darker;
  }
  li:hover li:hover > a {
    background: $cs-teal-darker;
  }
  li li a {
    background: $cs-teal-dark;
  }
  li:hover li:hover li a:hover {
    background: $cs-teal-darkest;
  }
  li:hover li:hover li a {
    background: $cs-teal-darker;
  }
  ul ul {
    padding: 0;
    z-index: 1000;
  }
  ul ul ul {
    padding: 0;
    left: 100%;
    top: 0;
  }
  ul:after,
  ul:before {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  ul:after {
    clear: both;
  }
  #nav-main {
    li ul li {
      border-top: 0;
    }
    font-size: 1.2em;
    font-weight: 300;
  }
}

#main {
  margin: 15px auto;
  .dashboard {
    margin: 0 auto;
    .col {
      float: left;
      width: 324px;
    }
    .col.col-left {
      margin-right: 30px;
    }
    ul {
      margin: 1em 0;
    }
    ol {
      margin: 1em 0;
    }
  }
  h1.block-intro {
    background-color: $cs-white;
    color: $cs-teal;
    margin-bottom: 20px;
    padding: 11px 14px;
    text-shadow: 0 2px 1px rgba(255, 255, 255, 1);
  }
  .block {
    margin-bottom: 20px;
    .block-title {
      color: $cs-teal;
      background-color: $cs-white;
      padding: 9px 14px;
      h2 {
        color: $cs-teal;
        margin: 0;
      }
    }
    .block-content {
      background-color: $cs-white;
      padding: 12px 14px 6px;
      .img-left {
        float: left;
        margin: 0 1em 1em 0;
      }
      .img-right {
        float: right;
        margin: 0 0 1em 1em;
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      > li {
        background: url("/art/img/bullet.png") no-repeat 1px 8px;
        list-style-type: none;
        padding-left: 13px;
      }
      ul {
        margin-bottom: 0.5em;
        margin-top: 0.5em;
      }
      ol {
        margin-bottom: 0.5em;
        margin-top: 0.5em;
      }
    }
    ol {
      margin-left: 21px;
      padding: 0;
      ol {
        margin-bottom: 0.5em;
        margin-top: 0.5em;
      }
      ul {
        margin-bottom: 0.5em;
        margin-top: 0.5em;
      }
    }
  }
  .block.block-welcome {
    .block-title {
      // background-color: $background_color_8;
      // background-image: -moz-linear-gradient(top, #ef3e35 0%, #d12b22 100%);
      // background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ef3e35), color-stop(100%,#d12b22));
      // background-image: -webkit-linear-gradient(top, #ef3e35 0%, #d12b22 100%);
      // background-image: -o-linear-gradient(top, #ef3e35 0%, #d12b22 100%);
      // background-image: -ms-linear-gradient(top, #ef3e35 0%, #d12b22 100%);
      // background-image: linear-gradient(to bottom, #ef3e35 0%, #d12b22 100%);
    }
    .block-content {
      padding: 2px 14px 8px;
    }
  }
  .block.block-quick-links {
    ul {
      margin-top: 0;
    }
  }
  .block.block-whats-new {
    .block-content {
      padding: 4px 14px 8px;
    }
    ul {
      list-style-type: none;
      margin: 0;
      li {
        background: none;
        border-top: 1px solid $cs-teal-light;
        padding: 0.8em 0;
        margin: 0;
        &:first-child {
          border-top: none;
        }
      }
    }
  }
}

#footer {
  margin: 0 auto;
  .inner {
    color: $cs-grey-user;
    border-top: 1px solid $cs-grey-light;
    font-size: 12px;
    padding: 16px 0 20px;
    text-align: center;
  }
}

.ir {
  background-color: $cs-transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
  }
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable {
  &:active {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.invisible {
  visibility: hidden;
}

.clearfix {
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  *zoom: 1;
}

@media print {
  body {
    background: none;
  }
  #header {
    display: none;
  }
  #main {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .classic-table {
    border: 1pt solid #000000;
  }
  select {
    border: 1px solid $cs-white !important;
    font-size: 24px !important;
    font-weight: bold;
  }
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
  .visible-print {
    display: block !important;
  }
}

@media print2 {
  * {
    background: transparent !important;
    color: $cs-black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a {
    text-decoration: underline;
    &:visited {
      text-decoration: underline;
    }
  }
  a[href] {
    &:after {
      content: " (" attr(href) ")";
    }
  }
  abbr[title] {
    &:after {
      content: " (" attr(title) ")";
    }
  }
  .ir {
    a {
      &:after {
        content: "";
      }
    }
  }
  a[href^="javascript:"] {
    &:after {
      content: "";
    }
  }
  a[href^="#"] {
    &:after {
      content: "";
    }
  }
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr {
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p {
    orphans: 3;
    widows: 3;
  }
  h2 {
    orphans: 3;
    widows: 3;
    page-break-after: avoid;
  }
  h3 {
    orphans: 3;
    widows: 3;
    page-break-after: avoid;
  }
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
  .visible-print {
    display: block !important;
  }
  select {
    border: 1px solid $cs-white !important;
  }
}

/*================================
				&UNIVERSAL CLASSES
================================*/

.desktop-hidden,
.desktop-hidden * {
  display: hidden;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.padding-top {
  padding-top: 20px;
}

.padding-top-small {
  padding-top: 5px;
}

.padding-bottom {
  padding-bottom: 20px;
}

.padding-bottom-small {
  padding-bottom: 10px;
}

.margin-left-small {
  margin-left: 5px;
}

.margin-right-small {
  margin-right: 5px;
}

.margin-left {
  margin-left: 10px;
}

.margin-right {
  margin-right: 10px;
}

.hover-fade:hover {
  opacity: 0.5;
}

.no-border {
  border: none;
}

.text-center {
  text-align: center;
}

input[type="text"],
input[type="password"] {
  height: 30px;
  border: 1px solid $cs-grey-light;
  border-radius: 7px;
}

.custom-radio {
  input[type="radio"]:checked {
    border: 5px solid $cs-teal;
    background-color: $cs-teal;
  }
  input:nth-child(3) {
    margin-left: 10px;
  }
}

.btn-grey {
  background-color: $cs-grey-lighter;
  border: 1px solid $cs-grey-light;
  color: $cs-grey;
  font-size: 13px;
  font-weight: lighter;
  height: 35px;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-grey:hover {
  background-color: $cs-grey-light;
  color: $cs-grey-dark;
}

.full-width {
  width: 100%;
}

h1.headspan {
  text-align: left;
  span {
    font-weight: bold;
  }
}

.no-top {
  margin-top: 0;
  padding-top: 0;
}

.no-border {
  border: none;
}

.no-bottom {
  padding-bottom: 0;
  margin-bottom: 0;
}

.border-bottom-row {
  width: 100%;
  border-bottom: 1px solid $cs-grey-lighter;
  margin-bottom: 10px;
}

.td-padding {
  padding-top: 15px !important;
}

.input-title {
  margin-right: 0;
  padding-right: 0;
  p {
    vertical-align: middle;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.input-title-name-val-row {
  display: flex;
  div {
    width: 100%;
    legend {
      width: fit-content;
      border-bottom: 0;
      font-size: 18px;
    }
  }
  div:nth-child(2) {
    width: 160%;
  }
}

.input-title-notes {
  margin-right: 0;
  padding-right: 0;
  p {
    vertical-align: middle;
    margin-bottom: 0;
    margin-top: 21.5px;
  }
}

.date-selector-container {
  div {
    margin: 0;
    margin-left: 5px;
    margin-bottom: 10px;
    padding: 0;
  }
  div:nth-child(1) {
    margin-left: 15px;
  }
}

.next-button-span {
  img {
    height: 10px;
    width: 10px;
    margin-right: -5px;
    margin-bottom: 2px;
  }
}

.notes-container {
  textarea {
    resize: none;
    width: 100%;
    border: 2px solid $cs-grey-light;
    background-color: $cs-white;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    display: inline-block;
    min-width: 120px;
    border: 1px solid $cs-grey-light;
    background-color: $cs-grey-lightest;
    background-size: 10px;
    height: 125px;
    background-position: right 8px center;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: $cs-grey;
    padding-left: 10px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
}

.hide-unused-check {
  padding-bottom: 10px !important;
  padding-top: 10px;
}

.hide-unused-title {
  width: 127px;
}

/*================================
	      &PROVIDER TYPE
================================*/

.provider-types-page {
  .provider-types-table {
    table {
      // th:nth-child(1), :nth-child(2) {
      // 	width: 30%;
      // }
      td:last-child {
        width: 40px;
      }
      td:nth-child(2) {
        text-align: center;
      }
      th:nth-child(2) {
        text-align: center;
      }
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
  .add-provider-row {
    input {
      border-radius: 7px;
      border: 1px solid $cs-grey-light;
      height: 30px;
    }
  }
}

.provider-type-delete-page {
  .provider-type-delete-table {
    table {
      th:nth-child(3) {
        text-align: center;
      }
      td:nth-child(3) {
        text-align: center;
      }
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.provider-types-edit-page {
  .add-provider-row {
    input {
      height: 30px;
      border-radius: 7px;
      border: 1px solid $cs-grey-light;
    }
  }
}

/*================================
	        &SKILLS PAGE
================================*/

.skills-page {
  input {
    height: 30px;
    border: 1px solid $cs-grey-light;
    border-radius: 7px;
  }
  button {
    width: 60px;
  }
  .skills-table {
    a {
      width: 55px;
    }
    table {
      th:nth-child(2) {
        text-align: center;
      }
      th:nth-child(3) {
        text-align: center;
      }
      td:nth-child(2) {
        text-align: center;
      }
      td:nth-child(3) {
        text-align: center;
      }
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.skills-delete-page {
  .skills-delete-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.skills-edit-page {
  .add-skills-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
      input {
        height: 30px;
        border: 1px solid $cs-grey-lighter;
        border-radius: 7px;
      }
    }
  }
}

/*================================
	      &FACILITIES PAGE
================================*/

.facilities-page {
  .facilities-table {
    table {
      th:nth-child(3) {
        text-align: center;
      }
      th:nth-child(2) {
        text-align: right;
      }
      td:nth-child(3) {
        text-align: center;
      }
      td:nth-child(2) {
        text-align: right;
      }
      td:last-child {
        width: 50px;
      }
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.facility-add-page {
  span {
    color: $cs-red;
    padding-right: 5px;
  }
  button {
    width: 100px;
  }
  .facility-add-form {
    .address-inputs {
      input:nth-child(2) {
        margin-top: 5px;
      }
    }
  }
}

.facility-edit-page {
  span {
    color: $cs-red;
    padding-right: 5px;
  }
  button {
    width: 100px;
  }
  .facility-add-form {
    .address-inputs {
      input:nth-child(2) {
        margin-top: 5px;
      }
    }
  }
}

.facilities-delete-page {
  table {
    th:nth-child(3) {
      text-align: center;
    }
    th:nth-child(4) {
      text-align: left;
    }
    td:nth-child(3) {
      text-align: center;
    }
    td:nth-child(4) {
      text-align: left;
    }
    td {
      height: 50px;
      vertical-align: middle !important;
    }
  }
}

/*======================================
						&JOB EDITOR
=======================================*/

.sortable {
  margin-top: 5px;
  margin-bottom: 5px;
  list-style: none;
  li {
    margin-left: -10px;
  }
}

.jobs-page {
  button {
    width: 65px;
  }
  .jobs-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.add-job-page {
  .add-properties-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
        input {
          height: 30px;
          border: 1px solid $cs-grey-lighter;
          border-radius: 7px;
        }
      }
    }
  }
  .job-add-form {
    padding: 30px 50px;
    .form-group label {
      font-size: 14px;
      text-align: left !important;
      &.required::before {
        content: "*";
        color: $cs-red;
        margin-right: 3px;
      }
    }
    .control-field {
      min-height: 30px;
      display: flex;
      align-items: center;
    }
    .button-group {
      margin-top: 30px;
      .btn {
        margin: 0.5em;
      }
    }
    #tigerschedule-role-token {
      min-height: 30px;
      outline: none;
      width: 100%;
    }
    #role-token {
      margin-right: 1em;
      &:empty {
        display: none;
      }
    }
  }
}

.job-day-types-page {
  .job-day-types-select {
    div {
      margin-top: 2px;
    }
    .select-container {
      select {
        width: auto !important;
      }
    }
    .days-selection {
      margin-top: 10px;
    }
  }
}

.jobs-provider-page {
  .delete-jobs-provider-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.job-delete-page {
  .job-delete-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.job-edit-page {
  .job-edit-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
  .job-edit-time-day-table {
    table {
      th:nth-child(2) {
        text-align: right;
      }
      th:last-child {
        text-align: center;
      }
      td:nth-child(2) {
        text-align: right;
      }
      td:last-child {
        text-align: center;
      }
    }
  }
  .job-edit-day-types-table {
    table {
      text-align: center;
      th {
        text-align: center;
      }
    }
  }
  .job-edit-enabled-table {
    table {
      th:last-child {
        text-align: center;
      }
      td:last-child {
        text-align: center;
      }
    }
  }
}

/* ======================================
						   &DAY TYPES
========================================*/

.day-types-page {
  .day-types-select {
    .days-selection {
      margin-top: 10px;
    }
  }
}

/* ======================================
					 &PRIMARY HOLIDAYS
========================================*/

.pri-holiday-page {
  .pri-holiday-table {
    table {
      th:nth-child(3) {
        text-align: center;
      }
      th:nth-child(2) {
        text-align: right;
      }
      td:nth-child(3) {
        text-align: center;
      }
      td:nth-child(2) {
        text-align: right;
      }
      td:last-child {
        width: 50px;
      }
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
  .pri-holiday-default-table {
    table {
    }
  }
}

//pri-holiday-add-page used for edit page also
.pri-holiday-add-page {
  .add-pri-holiday-form-properties {
    .row {
      margin-right: 100px;
      padding: 0 0 10px 30px;
    }
    span {
      color: $cs-red;
      padding-right: 5px;
    }
    #name,
    #abbrev {
      width: 100%;
    }
  }
  .add-pri-holiday-form-date-formula {
    .select-label-row {
      display: flex;
      flex-direction: column;
    }
    label {
      padding-left: 30px;
    }
    .row {
      padding-top: 15px;
    }
  }
  #required-field-message {
    text-align: center;
    margin-top: 20px;
    span {
      color: $cs-red;
    }
  }
  .error-msg {
    p {
      color: red;
      text-align: center;
    }
  }
  h2 {
    text-align: center;
  }
  .anual-choice {
    margin-bottom: 5px;
  }
  .anual-selection {
    .select-container {
      select {
        width: 150px;
      }
    }
  }
  .week-choice {
    margin-bottom: 5px;
  }
  .week-selection {
    .select-container {
      select {
        width: 100px;
      }
    }
  }
  .equation-choice {
    margin-bottom: 5px;
  }
  .equation-selection {
    .select-container {
      select {
        width: 70px;
      }
    }
  }
}

.pri-holiday-delete-page {
  .pri-holiday-delete-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.pri-holiday-edit-page {
  .pri-holiday-properties-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
      input {
        height: 30px;
        border: 1px solid $cs-grey-lighter;
        border-radius: 7px;
      }
      select {
        background-color: $cs-white;
      }
    }
  }
}

/* ======================================
						&PROVIDER REQUESTS
========================================*/

.update-cell {
  a {
    width: 32px;
    height: 32px;
    margin-left: 20%;
    background-size: 20px 20px;
    background-color: $cs-teal-dark;
    background-image: url("../images/cs-assets/cs-edit.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}

.go-button-container {
  input {
    vertical-align: middle;
    background: none;
    background-color: $cs-blue;
    width: 50px;
  }
}

.provider-requests-page {
  .advanced-search {
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid $cs-grey-light;
    font-weight: bold;
    vertical-align: middle;
    text-align: right;
    font-size: 16px;
  }
  .provider-requests-row {
    width: 100%;
    border-bottom: 1px solid $cs-grey-light;
    margin-bottom: 20px;
  }
  table {
    margin-bottom: 100px;
    thead {
      background-color: $cs-grey-lightest;
      color: $cs-grey;
      th:nth-child(1) {
        width: 20%;
      }
      th:nth-child(2) {
        width: 12%;
      }
      th:nth-child(3) {
        width: 12%;
      }
      th:nth-child(4) {
        width: 12%;
      }
      th:nth-child(5) {
        width: 16%;
      }
      th:nth-child(6) {
        width: 20%;
      }
      th {
        border: none;
        padding-left: 10px;
      }
    }
    tbody {
      color: $cs-grey;
      tr {
        td:nth-child(even) {
          background-color: $cs-grey-lightest;
        }
        td {
          padding-top: 15px;
          padding-left: 10px;
          img {
            vertical-align: middle;
            width: 12%;
            margin-left: 45%;
          }
        }
      }
    }
  }
}

/* ======================================
			   &PROVIDER LIST - SKILLS
========================================*/

.swap-select {
  select {
    background-color: $cs-grey-lighter;
    border-radius: 7px;
    padding: 5px;
    margin-bottom: 5px;
  }
}

.swap-arrow-button {
  padding-top: 100%;
  font-weight: bold;
}

.provider-skills-page {
  .full-height {
    select,
    a {
      height: 100px;
      width: 100%;
    }
    span {
      text-align: center;
    }
  }
}

/*======================================
					&PROVIDER LIST PAGE
======================================*/

.provider-list-page {
  .provider-list-table {
    table {
      td {
        height: 50px;
        vertical-align: middle !important;
      }
    }
  }
}

.provider-delete-page {
  .provider-delete-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

.provider-holiday-page {
  .provider-holiday-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

/*======================================
					&SCHEDULER PAGE
======================================*/

.scheduler-table {
  table {
    td {
      height: 50px !important;
      vertical-align: middle !important;
    }
  }
}

.delete-schedulers-table {
  table {
    td {
      height: 50px;
      vertical-align: middle !important;
    }
  }
}

/*======================================
					&SCHEDULE PAGE
======================================*/

.schedule-intervals-page {
  label {
    padding-top: 5px;
  }
  .schedule-intervals-table {
    table {
      th:last-child {
        text-align: right;
      }
      td:last-child {
        text-align: right;
      }
      td {
        height: 50px;
        vertical-align: middle !important;
      }
    }
  }
}

/*======================================
					&IMPORT / EXPORT PAGE
======================================*/

.import-export-page {
  .import-export-table {
    table {
      td {
        height: 50px;
        vertical-align: middle !important;
      }
    }
  }
}

/*======================================
					&BATCH IMPORT PAGE
======================================*/

.batch-import-page {
  .batch-import-table {
    table {
      td {
        height: 50px;
        vertical-align: middle !important;
      }
    }
  }
}

.import-history-page {
  .import-history-table {
    table {
      td {
        height: 50px;
        vertical-align: middle !important;
      }
    }
  }
}

/*======================================
					&MANUAL SCHEDULE PAGE
======================================*/

.man-schedule-jobs-page {
  .man-schedule-form {
    table {
      td {
        height: 50px;
        vertical-align: middle !important;
      }
      td.indicator_glyph_table_cell {
        width: 40px;
      }
      td.job_description_table_cell {
        width: 350px;
        padding-right: 15px;
        padding-left: 15px;
      }
      td.scheduled_drop_down_table_cell {
        width: 300px;
      }
    }
    .d-flex {
      display: flex;
    }
    .ml-40 {
      margin-left: 40px;
    }
    .flex-1 {
      flex: 1;
    }
  }
}

/* ======================================
=========================================
						 &TALLY PAGES
=========================================
========================================*/

.tally-page {
  label {
    padding-top: 6px;
  }
  .select-container {
    padding-bottom: 25px;
    select {
      width: 100%;
    }
  }
  .date-selector-container {
    div {
      margin: 0;
      margin-left: 5px;
      margin-bottom: 10px;
      padding: 0;
    }
    div:nth-child(1) {
      margin-left: 15px;
    }
  }
  .custom-format-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4) {
      width: 25px;
    }
  }
  .custom-format-form {
    ul {
      padding: 10px;
      li {
        border-radius: 7px;
        cursor: pointer;
        label {
          padding-left: 10px;
        }
        a {
          float: right;
          padding-right: 10px;
          padding-top: 5px;
        }
      }
    }
    .orientation-select-container {
      border-radius: 5px;
      padding-bottom: 0;
      width: 290px;
      select {
        width: 100%;
        margin-top: 2px;
        padding-bottom: 0;
        background-color: $cs-grey-lightest;
        border: 1px solid $cs-grey-light;
        cursor: pointer;
      }
    }
    .subtotal-input {
      height: 35px;
      border-radius: 7px;
      border: 1px solid $cs-grey-light;
      padding-left: 10px;
      color: $cs-grey;
    }
    .column-list {
      select {
        width: 30%;
        background-color: $cs-white;
      }
    }
  }
}

/*======================================
					&UNDO PAGE
======================================*/

.undo-page {
  .undo-page-table {
    table {
      td {
        height: 50px;
        vertical-align: middle !important;
      }
    }
  }
}

.undo-calendar {
  .undo-to-label {
    margin-right: 21px !important;
  }
  .undo-button {
    margin-left: 30px !important;
  }
}

/*======================================
		White Board - Notifications
======================================*/

.create-notification {
  display: inline;
  .notification-line {
    margin: 10px 0 10px 0;
    display: inline;
    line-height: 40px !important;
    > .select-container > select {
      padding-right: 20px;
    }
  }
  .checkbox {
    margin: 20px !important;
  }
  .notifications-phone {
    height: 30px;
    width: 150px;
    border: 1px solid #d8dce3;
    border-radius: 7px;
  }
  .is-active-radio-buttons {
    margin: 10px 0 10px 0;
  }
}

.notification-help-text {
  text-align: center;
  font-size: 14px;
  font-style: italic;
  padding-top: 20px;
}

/*======================================
			Merged Daily View
======================================*/

.merged-daily-page {
  .btn {
    font-size: 1.2em !important;
    font-weight: 600;
  }
  .merged-daily-add-note-button {
    margin-left: 2px;
  }
  .merged-daily-table,
  .merged-daily-dayoff-table {
    width: 100%;
    display: table;
    background-color: $cs-white;
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    thead {
      border: 2px solid $cs-grey-table-border;
      td {
        background-color: $cs-grey-lightest;
        color: $cs-grey;
        padding-top: 20px;
        padding-left: 12px;
        padding-bottom: 25px;
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
    a {
      color: $cs-grey-dark;
    }
  }
  .merged-daily-dayoff-table {
    #provider {
      border-right: 2px solid $cs-grey-table-border;
    }
    .data-row {
      td {
        border: 2px solid $cs-grey-table-border;
        background-color: $cs-grey-lightest;
        height: 40px;
        color: $cs-grey;
        padding: 6px 18px 8px 12px;
        font-size: 1.4rem;
        vertical-align: top;
        text-align: left;
        a {
          color: $cs-grey;
        }
      }
    }
  }
  td.p-line-through p {
    text-decoration: line-through;
  }
  .protocol_container p {
    padding: 0px;
    margin: 0px;
  }
  .separator {
    height: 30px;
  }
  .group-table {
    border: 2px solid $cs-grey-table-border;
    margin-bottom: 20px;
    display: table-row-group;
    &:nth-child(2) {
      td {
        border-top: none;
      }
    }
    .dept-row {
      td {
        vertical-align: top;
        text-align: left;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
      }
      #empty-row {
        padding: 0;
        margin: 0;
        height: 1px;
        line-height: 0;
        font-size: 0;
      }
    }
  }
  .data-row {
    td {
      border: 2px solid $cs-grey-table-border;
      border-top: 0;
      background-color: $cs-grey-lightest;
      min-height: 70px;
      color: $cs-grey;
      padding: 10px 12px 16px 12px;
      font-size: 1em;
      vertical-align: top;
      text-align: left;
      a {
        color: $cs-grey;
      }
    }
  }
  #single-event-data {
    border-right: 2px solid $cs-grey-table-border;
    border-bottom: 2px solid $cs-grey-table-border;
  }
  .dept-data {
    background-color: $cs-white;
    border-right: none;
    border-bottom: none;
    a {
      color: $cs-grey-darkest;
      font-weight: bold;
      font-size: 1.2em;
      text-decoration: underline;
    }
  }
  .note-row {
    height: 40px;
    border-top: none;
    td {
      font-size: 1em;
      color: $cs-grey;
      a {
        color: $cs-grey-darkest;
        font-weight: bold;
      }
    }
  }
  .merged-daily-final-note {
    padding: 10px 12px 10px 12px;
  }
  .group-protocol {
    padding: 10px 12px;
    font-size: 1em;
    color: $cs-grey;
  }
  .group-note {
    height: 40px;
    font-size: 1em;
    color: $cs-grey;
  }
  .change-icon {
    width: 30px;
    height: 30px;
  }
  .on-now td {
    background-color: $cs-table-green;
    color: $cs-black;
    a {
      color: $cs-black;
    }
  }
  .merged-daily-date {
    color: $cs-grey-dark;
    font-size: 1.4em;
    font-weight: 700;
    padding-top: 20px;
    padding-left: 20px;
  }
  .merged-daily-switch {
    padding-top: 10px;
    height: 70px;
    input[type="checkbox"] {
      display: none;
    }
    .switch-label {
      padding-bottom: 20px;
      margin-right: 10px;
      display: inline-block;
      position: relative;
      top: -24px;
      color: $cs-grey-dark;
    }
  }
  .merged-daily-button {
    margin-top: -4px;
    margin-left: -4px;
  }
  .merged-daily-dropdown-button {
    margin-top: -4px;
  }
  .header-row {
    padding: 20px 5px;
  }
  .calendar-name {
    font-size: 2em;
    #mdv-new-redirect {
      font-size: 0.5em;
    }
  }
  #login {
    font-size: 1.5em;
    color: $cs-grey-dark;
  }
  .dropdown-label {
    font-size: 1.2em;
  }
  .merged-daily-hidden {
    display: none;
  }
  .merged-daily-table-head {
    vertical-align: top;
    text-align: left;
  }
  .phone-title {
    min-width: 100px;
  }
  .phone-title-none {
    width: 0;
    padding: 0 !important;
  }
  .table {
    border-collapse: collapse;
    a {
      text-decoration: none;
    }
  }
  .protocol_container a {
    text-decoration: underline;
  }
}

.slide-toggle {
  display: block;
  position: relative;
  flex: none;
  width: 50px;
  height: 30px;
  border-radius: 30px;
  background-color: #d7d2cb;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 1;
  margin: 10px;
  &::before,
  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 1px;
    border-radius: 30px;
    height: 28px;
    background-color: #fff;
    transform: translate3d(0, 0, 0);
    transition: 0.2s cubic-bezier(0, 1.1, 1, 1.1);
  }
  &::before {
    z-index: -1;
    width: 48px;
    right: 1px;
    transform: scale(1);
  }
  &::after {
    z-index: 1;
    width: 28px;
    left: 1px;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
  }
  input:checked + & {
    background-color: #4dde5e;
    &::before {
      transform: scale(0);
    }
    &::after {
      transform: translate3d(20px, 0, 0);
    }
  }
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.padding-top {
  padding-top: 20px;
}

.padding-bottom {
  padding-bottom: 20px;
}

.padding-bottom-small {
  padding-bottom: 10px;
}

.margin-left-small {
  margin-left: 5px;
}

.margin-right-small {
  margin-right: 5px;
}

.margin-left {
  margin-left: 10px;
}

.margin-right {
  margin-right: 10px;
}

.margin-top-small {
  margin-top: 5px;
}

.hover-fade:hover {
  opacity: 0.5;
}

.no-border {
  border: none !important;
}

.text-center {
  text-align: center;
}

.custom-radio {
  input[type="radio"]:checked {
    border: 5px solid $cs-teal;
    background-color: $cs-teal;
  }
  input:nth-child(3) {
    margin-left: 10px;
  }
}

.btn-grey {
  background-color: $cs-grey-lighter;
  border: 1px solid $cs-grey-light;
  color: $cs-grey;
  font-size: 13px;
  font-weight: lighter;
  height: 35px;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-grey:hover {
  background-color: $cs-grey-light;
  color: $cs-grey-dark;
}

.full-width {
  width: 100%;
}

h1.headspan {
  text-align: left;
  span {
    font-weight: bold;
  }
}

.no-top {
  margin-top: 0;
  padding-top: 0;
}

.no-border {
  border: none;
}

.no-bottom {
  padding-bottom: 0;
  margin-bottom: 0;
}

.border-bottom-row {
  width: 100%;
  border-bottom: 1px solid $cs-grey-lighter;
  margin-bottom: 10px;
}

.padding-top-small {
  padding-top: 5px;
}

.date-three-select {
  select:nth-child(1) {
    width: 140px;
  }
  select:nth-child(2) {
    width: 50px;
  }
  select:nth-child(3) {
    width: 90px;
  }
}

.full-select {
  select {
    width: 100%;
  }
}

td {
  vertical-align: middle;
}

input[type="text"],
input[type="password"] {
  padding-left: 5px;
}

.vert-align {
  tr {
    td {
      vertical-align: middle !important;
    }
  }
}

/* ======================================
					&SCHEDULE CALENDER (Year)
========================================*/

.calendar-year-page {
  table {
    tr:nth-child(1) {
      border-top: none;
    }
  }
  h1 {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid $cs-grey-light;
  }
  .yearview-top-row {
    .select-container:nth-child(2),
    :nth-child(3) {
      select {
        width: 245px;
        float: right;
        margin-right: 5px;
      }
    }
    .holiday-box {
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
    .holiday-red {
      background-color: red;
    }
    .holiday-green {
      background-color: green;
    }
  }
  .yearview-second-row {
    .btn {
      padding-left: 0;
      padding-right: 0;
    }
    .btn:nth-child(1) {
      width: 85px;
    }
    .yearview-scheduling {
      .btn {
        width: 105px !important;
      }
    }
  }
  .days-of-week {
    background-color: $cs-teal-lighter;
    color: $cs-teal;
    text-align: center;
    td {
      border: none;
      width: 14.285%;
    }
  }
  .month-view {
    border: 1px solid black;
  }
  .month-name {
    background-color: $cs-teal;
    width: 100%;
    text-align: center;
    height: 50px;
    padding-top: 10px;
    border-top: 1px solid $cs-grey-light;
    border-left: 1px solid $cs-grey-light;
    border-right: 1px solid $cs-grey-light;
    a {
      color: $cs-white;
      font-size: 18px;
    }
  }
  .calendar-days {
    background-color: white;
  }
  .provider-highlighted {
    outline: 2px solid #238bd4;
    outline-offset: -3px;
  }
}

/* ======================================
				&SCHEDULE CALENDER (Month)
========================================*/

.month-calendar-table {
  margin-left: -10px;
  th {
    border: none;
  }
  td:nth-child(1) {
    border: none;
  }
  td {
    border: 1px solid $cs-grey-light;
  }
  #year-td {
    border: none !important;
  }
  .year-calendar-table {
    td:nth-child(1) {
      border: 1px solid $cs-grey-light;
    }
  }
}

.month-calendar-custom-table {
  img {
    padding-top: 0 !important;
    height: 15px !important;
    width: 12.5px !important;
  }
  th {
    border: none;
  }
  td {
    border: 1px solid $cs-grey-light;
  }
  & > tr:first-child {
    background-color: red;
  }
  .custom-table-day {
    border: none;
    font-family: $cs-font;
    font-size: 11px;
  }
  .calendar-small {
    font-size: 12px;
  }
  .custom-table-day-number {
    border: none;
    font-family: $cs-font, verdana, sans-serif;
    font-size: 17px;
  }
  .weekday-head:nth-child(2) {
    border-left: none !important;
  }
}

.weekday-head {
  text-align: center;
  font-weight: bold;
  color: $cs-grey;
  font-size: 16px;
  border: none !important;
}

.weekday-head:nth-child(2) {
  border-left: 1px solid $cs-grey-lighter !important;
}

.weekday-head:nth-child(8) {
  border-right: 1px solid $cs-grey-lighter !important;
}

.filters-label {
  width: 10%;
  position: absolute;
  top: 0;
  left: 5px;
  margin-left: 80px;
}

.filter-label-container {
  display: inline-block;
  text-align: right;
  label {
    position: absolute;
    right: 210px;
    top: 0;
    margin-bottom: 0;
    margin-top: 5px;
  }
}

.filters-bar {
  float: right;
  padding-top: 0;
  margin-top: -5px;
  background-color: $cs-grey-lighter;
  border-radius: 7px;
  border: 1px solid $cs-grey-light;
  a {
    color: $cs-grey;
    text-align: center;
    text-decoration: none;
    padding: 10px 10px;
    display: inline-block;
  }
  a:nth-child(1),
  :nth-child(2) {
    border-right: 1px solid $cs-grey-light;
  }
  a:hover {
    background-color: $cs-grey-light;
    color: $cs-grey-dark;
  }
  .filter-on {
    background-color: lighten($cs-red, 10);
    color: $cs-white;
    text-align: center;
    text-decoration: none;
    padding: 10px 10px;
    display: inline-block;
    &:hover {
      background-color: lighten($cs-red, 2);
      color: $cs-white;
    }
  }
  a:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  a:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}

.calendar-month-page {
  h1 {
    text-align: center;
  }
  h2.new-calendar-link {
    text-align: center;
    font-weight: lighter;
    color: $cs-grey-dark;
    margin: 0 0 0.5em;
  }
  .month-first-row {
    border-bottom: 1px solid $cs-grey-light;
  }
  .month-second-row {
    padding-bottom: 5px;
    padding-top: 0;
    .month-list {
      color: $cs-grey;
      text-decoration: underline;
      padding-right: 2%;
      text-transform: uppercase;
      font-weight: bold;
    }
    .month-list:hover {
      color: $cs-blue;
    }
  }
  .calendar-head-dark {
    background-color: $cs-grey-light;
    height: 45px;
    padding-left: 15px;
    div:nth-child(1) {
      background-color: $cs-teal;
      height: 100%;
      a {
        height: 100%;
        width: 30px;
        img {
          width: 7px;
        }
      }
      h1 {
        color: white;
        padding-top: 5px;
      }
    }
    .btn-grey {
      width: 90px;
      padding-left: 0;
      padding-right: 0;
    }
    .btn-grey:hover {
      background-color: $cs-grey-lightest;
    }
    .date-select-dropdown:hover select {
      background-color: $cs-grey-lightest;
      color: $cs-grey-dark;
    }
    .date-select-year {
      select {
        min-width: 0;
        width: auto !important;
      }
    }
    .date-select-month {
      select {
        min-width: 0;
        width: auto !important;
      }
      margin-right: 5px;
    }
    .month-dropdown {
      width: 50px;
    }
  }
  .day-expand {
    img {
      margin-top: 5px;
      height: 12.5px;
      width: 12.5px;
    }
  }
  .day-of-week {
    width: 14.285%;
    padding: 0 8px;
    .col-md-12 {
      b {
        padding-left: 3px;
        border-left: 3px solid $cs-teal !important;
      }
    }
  }
  .day-of-month {
    p {
      margin-top: 1px;
      font-size: 14px !important;
      text-align: right;
      color: $cs-grey-dark;
    }
    a.small_blk {
      font-size: 14px !important;
      color: $cs-grey-dark !important;
    }
  }
}

/* ======================================
					&MANUAL CALENDAR (month)
========================================*/

.day-container {
  min-height: 120px;
}

.grey-bar {
  height: 20px;
  background-color: $cs-grey-lightest;
  margin-bottom: 10px;
}

.border-bottom {
  border-bottom: 1px solid $cs-grey-lighter;
  padding-bottom: 10px;
  h1 {
    font-size: 18px;
  }
}

/* ======================================
						&CALENDAR LEGEND
========================================*/

.legend-all {
  h3 {
    margin-left: 25px;
    font-size: 18px;
    font-weight: bold;
    color: $cs-grey-dark;
  }
  p {
    padding-top: 5px;
    padding-left: 15px;
  }
  .legend-table {
    width: 100%;
    border-collapse: collapse;
    td {
      border: none !important;
    }
  }
  .legend-info {
    width: 25%;
    padding: 5px;
  }
  .legend-info-container {
    padding: 0;
    border: 1px solid $cs-grey-light;
    min-height: 150px;
  }
  .legend-info-header {
    background-color: $cs-grey-lighter;
    height: 50px;
    display: block;
    p {
      -webkit-margin-before: 0;
    }
    span {
      font-weight: bold;
      display: block;
      color: $cs-grey;
    }
  }
  .legend-contact {
    font-weight: bold;
    color: $cs-grey;
    padding-top: 10px;
    padding-left: 10px;
    text-transform: capitalize;
    div {
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 10px;
    }
  }
  .legend-job-container {
    height: 50px;
    background-color: $cs-grey-lighter;
    border: 1px solid $cs-grey-light;
    span {
      font-weight: bold;
      display: block;
    }
    .legend-job-info {
      p {
        font-weight: lighter;
      }
      span {
        text-transform: uppercase;
        color: $cs-grey;
      }
    }
  }
}

/* ======================================
						&PROVIDER REQUESTS
========================================*/

.request-archive {
  font-weight: bold;
  select {
    min-width: 0;
    font-weight: normal;
  }
  select:nth-child(1) {
    width: 45% !important;
    margin-left: 5px;
  }
  select:nth-child(2) {
    width: 30% !important;
    float: right;
  }
}

.update-cell {
  a {
    width: 32px;
    height: 32px;
    margin-left: 20%;
    // margin-top: 20px;
    background-size: 20px 20px;
    background-color: $cs-teal-dark;
    background-image: url("../images/cs-assets/cs-edit.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      width: 100%;
      padding-bottom: 10px;
      input {
        width: 100%;
      }
    }
  }
}

.go-button-container {
  input {
    vertical-align: middle;
    background: none;
    background-color: $cs-blue;
    width: 50px;
  }
  .btn {
    margin-top: -3px;
    margin-left: 5px;
  }
}

.provider-requests-page {
  .select-container {
    select {
      width: 224px;
    }
  }
  h1 {
    border-bottom: 0 !important;
  }
  .advanced-search {
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid $cs-grey-light;
    font-weight: bold;
    vertical-align: middle;
    text-align: right;
    font-size: 16px;
  }
  .provider-requests-row {
    width: 100%;
    border-bottom: 1px solid $cs-grey-light;
    margin-bottom: 20px;
  }
  table {
    margin-bottom: 100px;
    thead {
      background-color: $cs-grey-lightest;
      color: $cs-grey;
      th:nth-child(1) {
        width: 20%;
      }
      th:nth-child(2) {
        width: 12%;
      }
      th:nth-child(3) {
        width: 12%;
      }
      th:nth-child(4) {
        width: 12%;
      }
      th:nth-child(5) {
        width: 16%;
      }
      th:nth-child(6) {
        width: 20%;
      }
      th {
        border: none;
        padding-left: 10px;
      }
    }
    tbody {
      color: $cs-grey;
      tr {
        td:nth-child(even) {
          background-color: $cs-grey-lightest;
        }
        td {
          padding-top: 15px;
          padding-left: 10px;
          img {
            vertical-align: middle;
            width: 12%;
            margin-left: 45%;
          }
        }
      }
    }
  }
}

/* ======================================
						&REQUEST FORM
========================================*/

.request-form-page {
  .add-submit-row {
    margin-top: 10px;
    margin-bottom: 20px;
    .btn {
      width: 200px;
    }
    input {
      display: inline;
      margin-right: 5px;
    }
  }
}

.request-form-content {
  .request-type-dropdown {
    select,
    span {
      width: 100% !important;
    }
    #yearview_job_dropdown_1 {
      margin-top: -9px;
    }
  }
  .swap-select {
    select {
      width: 250px;
    }
  }
  p {
    font-weight: bold;
  }
}

/* ======================================
						&ADVANCED SEARCH
========================================*/

.request-search-page {
  .btn-grey {
    width: 200px;
  }
  label {
    padding-top: 10px;
  }
  .search-button {
    width: 100%;
  }
  .search-parameters {
    .full-select {
      select {
        width: 100%;
      }
    }
    .search-date-radio {
      select {
        margin-left: 0;
        margin-right: 0;
        min-width: 0;
        width: auto !important;
      }
      .search-date-select {
        .dash {
          padding-left: 17px;
          font-size: 24px;
          color: $cs-grey;
          opacity: 0.8;
        }
      }
      span {
        font-size: 14px;
        padding-left: 10px;
      }
    }
    .row {
      padding-top: 10px;
    }
    select {
      margin-top: 10px;
    }
    label {
      text-align: right;
    }
  }
  .search-results-container {
    background-color: white;
    td {
      padding-top: 20px;
    }
    td:nth-child(7) {
      padding-top: 10px;
    }
  }
  th {
    background-color: $cs-grey-lighter;
  }
}

/* ======================================
						&REQUEST STATUS
========================================*/

.request-status-page {
  h3 {
    text-align: center;
    font-size: 24px;
    margin-top: 0;
    color: $cs-grey-dark;
    span {
      color: $cs-grey-darker;
    }
  }
  h4 {
    text-align: center;
    margin-top: 10px;
    input {
      margin-right: 10px;
    }
  }
  label {
    text-align: right;
    float: right;
  }
  p {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
  .request-status-info {
    p {
      font-weight: normal;
      text-align: left;
    }
    .row {
      margin-bottom: 5px;
      min-height: 40px;
    }
    .row:nth-child(odd) {
      // background-color: $cs-grey-lighter;
    }
  }
}

/* ======================================
						&PROVIDER LIST
========================================*/

.provider-list-page {
  th,
  td {
    text-align: center;
  }
  input,
  a {
    height: 35px;
  }
  .btn {
    width: 100px;
    margin-left: 5px;
  }
  .provider-list-header {
    padding-bottom: 20px;
    input {
      display: block;
    }
    h2 {
      margin-top: 0;
      text-align: center;
      font-size: 24px;
    }
  }
  .password-section {
    background-color: red;
  }
}

/* ======================================
					&PROVIDER LIST - ADD
========================================*/

.provider-add-page,
.signup-settings-page {
  h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
  }
  p {
    text-align: center;
  }
  .error-msg {
    p {
      color: red;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .provider-add-form {
    .row {
      margin-bottom: 10px;
      .col-md-4 {
        text-align: right;
      }
    }
    span {
      color: $cs-red;
      padding-right: 5px;
    }
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
    .name-inputs {
      input:nth-child(1),
      :nth-child(3) {
        width: 44%;
      }
      input:nth-child(2) {
        width: 9.5%;
      }
    }
    .address-inputs {
      input:nth-child(2) {
        margin-top: 10px;
      }
    }
    .start-dates {
      select {
        min-width: 0;
      }
      select:nth-child(1) {
        width: 60%;
      }
      select:nth-child(2) {
        width: 39%;
        float: right;
      }
    }
    .select-contact {
      margin-top: 20px;
      select {
        min-width: 0;
        width: 100%;
        float: right;
      }
    }
    .provider-add-buttons {
      input,
      a {
        width: 120px;
        height: 35px;
        margin-left: 5px;
      }
    }
  }
}

/* ======================================
					&PROVIDER LIST - EDIT
========================================*/

.provider-edit-page {
  h2 {
    margin-top: 0;
  }
  .nap-table {
    th:nth-child(1) {
      width: 60%;
    }
    th:nth-child(2),
    :nth-child(3) {
      width: 20%;
    }
  }
  .provider-jobs-table {
    th {
      width: 15%;
    }
    th:nth-child(1) {
      width: 40%;
    }
  }
  .ptun-table {
    th {
      width: 30%;
    }
    th:nth-child(1) {
      width: 15%;
    }
    th:nth-child(2) {
      width: 25%;
    }
  }
}

.inactivate-form {
  .select-container {
    select {
      min-width: 0;
      width: auto !important;
    }
  }
}

/* ======================================
			&PROVIDER LIST - RECUR DAYS OFF
========================================*/

.provider-daysoff-page {
  .select-container {
    select {
      min-width: 0;
      width: 100%;
    }
  }
  .provider-daysoff-table {
    table {
      td {
        vertical-align: middle !important;
      }
    }
  }
}

.provider-delete-page {
  .btn {
    margin-left: 5px;
    height: 35px;
  }
}

/* ======================================
			   &PROVIDER LIST - SKILLS
========================================*/

.swap-select {
  select {
    background-color: $cs-grey-lighter;
    border-radius: 7px;
    padding: 5px;
    margin-bottom: 5px;
  }
}

.swap-arrow-button {
  padding-top: 100%;
  font-weight: bold;
}

.provider-skills-page {
  .full-height {
    select,
    a {
      height: 100px;
      width: 100%;
    }
    span {
      text-align: center;
    }
  }
}

/* ======================================
			   &PROVIDER LIST - NAV BAR
========================================*/

.provider-nav-bar {
  .middle-button {
    width: 100%;
    height: 35px;
  }
}

/* ======================================
		 &PROVIDER LIST - RES FACILITIES
========================================*/

.provider-facilities-page {
  table {
    th:nth-child(1) {
      width: 50%;
    }
    th:nth-child(2),
    :nth-child(3) {
      width: 20%;
    }
  }
}

/* ======================================
		    &PROVIDER LIST - JOBS
========================================*/

.provider-jobs-page {
  table {
    th {
      width: 12%;
    }
    th:nth-child(1) {
      width: 5%;
    }
    th:nth-child(2) {
      width: 40%;
    }
  }
}

/* ======================================
		  &PROVIDER LIST - COWORKER RES
========================================*/

.provider-coworker-page {
  table {
    th {
      width: 12%;
    }
    th:nth-child(1) {
      width: 5%;
    }
    th:nth-child(2) {
      width: 25%;
    }
    th:nth-child(3) {
      width: 20%;
    }
  }
}

/* ======================================
		  &PROVIDER LIST - HOLIDAY
========================================*/

.provider-holiday-page {
  .select-container {
    .btn {
      height: 40px;
    }
  }
  table {
    th:nth-child(1) {
      width: 40%;
    }
    th:nth-child(3) {
      input {
        width: 100%;
      }
      width: 40%;
    }
  }
}

/* ======================================
  		    &SCHEDULING ASSISTANT
========================================*/

.schedulers-page {
  .btn {
    height: 35px;
    margin-left: 5px;
  }
}

.scheduler-delete-page {
  .btn {
    margin-left: 5px;
    height: 35px;
  }
}

.scheduling-assistant-page {
  .select-container {
    select {
      width: 100%;
    }
  }
  .scheduler-bar {
    height: 20px;
  }
  #tr-title-bar {
    background-color: red;
  }
  .schedule-radio {
    label {
      font-size: 14px;
      padding-left: 5px;
      padding-right: 20px;
    }
    label.pull-right {
      padding: 0;
    }
  }
  .schedule-checkboxes {
    input {
      margin-right: 10px;
    }
  }
}

/* ======================================
  		&SCHEDULING - DAY TYPE INTERVALS
========================================*/

.schedule-intervals-page {
  .select-container {
    select {
      width: 100%;
    }
  }
  label {
    font-size: 14px;
    padding-top: 10px;
  }
  .schedule-bottom-buttons {
    input {
      margin-left: 5px;
    }
    .btn {
      height: 35px;
      width: 120px;
    }
  }
}

/* ======================================
  		&SCHEDULING - JOB PREFS
========================================*/

.schedule-jobprefs-page {
  .pref-repeat-stripe {
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .pref-repeat-stripe:nth-child(odd) {
    background-color: $cs-grey-lighter;
  }
}

/* ======================================
  		 &SCHEDULING - IMPORT / EXPORT
========================================*/

.drag-select {
  ul {
    background: none !important;
    li {
      border-radius: 7px;
      margin-bottom: 5px;
    }
  }
  .btn {
    color: white !important;
  }
}

.import-picker {
  padding-left: 4%;
  ul {
    border-radius: 7px;
    background-color: $cs-grey-lightest;
    width: 230px;
    padding: 5px;
  }
  button {
    margin-bottom: 5px;
    background-color: $cs-grey-lighter;
    border: 1px solid $cs-grey-light;
    border-radius: 7px;
    color: $cs-grey;
    font-size: 13px;
    font-weight: lighter;
    height: 35px;
    padding-left: 30px;
    padding-right: 30px;
  }
  button:nth-child(1) {
    margin-top: 15px;
  }
  button:nth-child(3) {
    margin-top: 25px;
  }
}

.import-export-page {
  .format-details {
    input,
    select {
      width: 100%;
    }
  }
}

/* ======================================
					&BATCH JOB IMPORT
========================================*/

.batch-import-page {
  .error-msg {
    color: red !important;
  }
  .import-picker {
    padding-left: 9%;
    ul {
      border-radius: 7px !important;
      border: 1px solid $cs-grey-lighter;
      background-color: $cs-grey-lightest;
      width: 230px;
      padding: 5px;
    }
  }
}

.batch-export-page {
  .select-container {
    select {
      min-width: 0;
      width: 100%;
    }
  }
}

h1 {
  padding-bottom: 20px;
  border-bottom: 1px solid $cs-grey-lighter;
}

/* ======================================
					&CUSTOM REPORT PAGE
========================================*/

.custom-report-page {
  background-color: white;
}

.custom-report-table {
  th:first-child {
    width: 20% !important;
  }
}

.landscape-table {
  .table-top-stripe {
    background-color: lighten($cs-grey-lightest, 3);
    height: 50px;
    margin-top: 5px;
  }
  .calendar-container {
    overflow-x: scroll;
    width: 100%;
  }
  .arrow-button {
    height: 50px;
    width: 35px;
    background-color: $cs-grey-lightest;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px 12px;
  }
  .left-arrow {
    // margin-left: -15px;
    background-image: url("../images/cs-assets/cs-left-arrow.svg");
  }
  .right-arrow {
    // margin-right: -15px;
    background-image: url("../images/cs-assets/cs-right-arrow.svg");
  }
  .select-container {
    select {
      width: auto !important;
    }
  }
  .horizontal-scroll {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-color: #42a7c6 silver;
    scrollbar-width: auto;
  }
  table {
    tr {
      td {
        border: 1px solid $cs-grey-light;
      }
    }
  }
}

.font-white {
  color: $cs-white !important;
}

/* ======================================
					&MONTH CALENDAR WHITEBOARD
========================================*/

.calendar-head-dark-alt {
  background-color: $cs-grey-lighter;
  height: 45px;
  padding-left: 15px;
  div:nth-child(1) {
    // background-color: $cs-grey-darkest;
    height: 100%;
    a {
      height: 100%;
      width: 30px;
      img {
        width: 7px;
      }
    }
    h1 {
      color: white;
      padding-top: 5px;
    }
  }
  .btn-grey {
    width: 90px;
    padding-left: 0;
    padding-right: 0;
  }
  .date-select-dropdown:hover select {
    background-color: $cs-grey-light;
    color: $cs-grey-dark;
  }
  .date-select-year {
    select {
      min-width: 0;
      width: auto !important;
    }
  }
  .date-select-month {
    select {
      min-width: 0;
      width: auto !important;
    }
    margin-right: 5px;
  }
  .month-dropdown {
    width: 50px;
  }
}

.simple-month-header {
  td {
    border: none !important;
  }
}

/* ======================================
					&WHITEBOARD MENU
========================================*/

.whiteboard-menu-page {
  .whiteboard-menu-row {
    margin-bottom: 30px;
    img {
      width: 45%;
    }
    a {
      color: $cs-black;
    }
    a:hover {
      color: $cs-teal;
    }
    h3 {
      padding-top: 0;
      span {
        font-weight: bold;
      }
    }
    ul {
      list-style-type: none;
    }
  }
}

/* ======================================
					&iCALENDAR SETTINGS
========================================*/

.ical-settings-page {
  img {
    width: 20px;
    height: 20px;
  }
  .ical-actions {
    div {
      padding: 0;
      padding-left: 5px;
      a {
        width: 100%;
      }
      a:last-child {
        margin-right: 10px;
      }
    }
  }
  .ical-settings-table {
    table {
      td {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}

/* ======================================
					&WHITEBOARD - YEAR
========================================*/

.whiteboard-year-row {
  background-color: $cs-grey-lightest;
  height: 75px;
  div {
    height: 75px;
  }
  a {
    height: 100%;
    width: 35px;
    margin-top: -20px;
    background-color: $cs-grey-light;
    background-position: center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  a.year-left {
    background-image: url("../images/cs-assets/cs-left-arrow.svg");
  }
  a.year-right {
    background-image: url("../images/cs-assets/cs-right-arrow.svg");
  }
}

/* ======================================
				&edit reoccurring days off
========================================*/

.edit-recur-daysoff-page {
  .select-container {
    select {
      width: 100%;
      min-width: 0;
    }
  }
}

/* ======================================
				&Provider iCalendar Settings
========================================*/

.prov-ical-settings-page {
  input[type="text"],
  textarea {
    width: 350px;
  }
}

/* ======================================
				&SCHEDULING ASSISTANT RULES
========================================*/

.sched-rules-page {
  h3 {
    text-align: center;
    padding-top: 0;
    margin-top: 0;
    font-weight: bold;
  }
  .italic {
    font-style: italic;
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 5px;
  }
  img {
    width: 25px;
    height: 25px;
  }
  .status-bar img {
    width: 220px !important;
  }
  .select-container {
    font-size: 16px;
    font-weight: lighter;
    select {
      height: 25px;
      margin-bottom: 10px;
      background-color: lighten($cs-teal-light, 25);
      color: $cs-teal-darker;
      font-weight: bold;
      padding-right: 20px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  input {
    margin-top: 10px;
  }
  .multi-select {
    select {
      height: 100px;
    }
  }
  .multi-narrow {
    select {
      width: 250px;
    }
  }
  .grey-background {
    background-color: $cs-grey-lighter;
  }
  table {
    thead {
      background-color: $cs-grey-lighter;
      border: 1px solid $cs-grey-light;
      th {
        text-align: center;
        color: $cs-grey;
      }
      th:nth-child(1) {
        width: 14%;
      }
      th:nth-child(2) {
        width: 9%;
      }
      th:nth-child(3) {
        padding-left: 15px;
        text-align: left;
      }
      th:nth-child(4) {
        width: 13%;
      }
    }
    tbody {
      td {
        border: 1px solid $cs-grey-light;
        font-size: 16px;
        padding-top: 15px;
      }
    }
  }
  .group-applied-table {
    margin-top: -5px;
    thead {
      background-color: $cs-white;
      border: none;
      th {
        border: none;
      }
    }
  }
}

.rule_eleven_button {
  margin-bottom: 5px;
  width: 65.5px;
}

.rule_edit_button {
  margin-bottom: 5px;
  width: 65.5px;
}

/* ======================================
					&UI DATE PICKER
========================================*/

.ui-datepicker {
  min-width: 350px;
  td {
    background-color: $cs-white;
    border: 1px solid $cs-white;
    a {
      background-color: $cs-white;
    }
  }
  th {
    background-color: $cs-white;
    color: $cs-black;
  }
}

.ui-state-prev {
  background: none !important;
  background-image: url("../images/cs-assets/cs-left-arrow.svg") !important;
}

.ui-widget-header,
.ui-datepicker-header {
  background: none !important;
  background-image: none !important;
  background-color: $cs-white !important;
  border: 1px solid $cs-white !important;
  border-radius: 1px !important;
}

.ui-datepicker span,
.ui-datepicker a {
  font-family: "Lato", sans-serif !important;
}

.ui-state-default a {
  text-align: center;
  height: 100%;
}

.ui-datepicker td:before {
  content: "";
  display: block !important;
}

.ui-datepicker td {
  position: relative !important;
  /* position elements relatively */
}

.ui-datepicker td a,
.ui-datepicker td span {
  display: block !important;
  text-align: center !important;
  /* center the date, optional */
  text-decoration: none !important;
}

.ui-datepicker td a,
.ui-datepicker td span {
  height: 30% !important;
  padding: 35% !important;
}

.ui-datepicker table {
  width: 100%;
  table-layout: fixed !important;
  /* sets width of each column equally (and in turn, their height) */
  font-size: 0.9em;
  border-collapse: collapse !important;
  margin: 0 0 0.4em !important;
}

.ui-datepicker-calendar th {
  font-size: 1.15em !important;
  font-weight: 300 !important;
  color: #797979 !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 0px #fff solid !important;
  color: #666;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 0px !important;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-highlight a {
  background: #3e789b none;
  /* a color that fits the widget theme */
  color: white;
  /* a color that is readeable with the color above */
}

.ui-datepicker-prev span {
  background-image: none !important;
}

.ui-datepicker-next span {
  background-image: none !important;
}

.ui-datepicker-prev {
  background-image: url("../images/left-arrow.png") !important;
  background-position: 0px 0px !important;
  background-repeat: no-repeat;
  height: 50px !important;
  width: 50px !important;
}

.ui-datepicker-next {
  background-image: url("../images/right-arrow.png") !important;
  background-position: 0px 0px !important;
  background-repeat: no-repeat;
  height: 50px !important;
  width: 50px !important;
}

.ui-state-hover .ui-datepicker-next-hover {
  background-image: none !important;
  border: 1px #fff solid !important;
}

.ui-datepicker-title {
  height: 50px;
}

.ui-datepicker-header {
  border: 0 #fff solid !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  border-bottom: 4px #f8f8f8 solid !important;
  margin-bottom: 15px !important;
}

.ui-datepicker-title span {
  height: 40px;
  line-height: 40px;
}

.ui-datepicker-month {
  font-size: 1.5em;
  font-weight: bold;
  color: $cs-grey-darkest;
}

.ui-datepicker-year {
  font-size: 1.5em;
  font-weight: 300;
  margin-left: 10px;
  color: $cs-grey-darkest;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #fbfbfb !important;
  color: #797979 !important;
  border: 1px #fff solid !important;
  font-size: 1.15em;
  font-weight: normal;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #f6fcff !important;
  color: $cs-teal-darkest !important;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid $cs-teal-light !important;
  font-weight: bold !important;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid $cs-teal-dark !important;
  background: $cs-blue-lighter !important;
  color: $cs-white !important;
}

/* ======================================
					&LOGIN PAGE
========================================*/

.login-page {
  width: 100%;
  min-height: calc(100vh - 200px);
  .login-image-row {
    background-color: $cs-white;
    padding: 0 20px 25px;
    img {
      height: 85px;
      margin-top: 12px;
      margin-bottom: -12px;
    }
  }
  .login-nav {
    height: 52.25px;
    background-color: $cs-teal;
    .login-home {
      height: 52.25px;
      width: 95px;
      border-left: 1px solid $cs-blue-lighter;
      a {
        display: block;
        text-decoration: none;
      }
      img {
        width: 20px;
        margin-top: 10px;
        margin-bottom: 2px;
      }
      p {
        color: white;
        text-align: center;
        font-size: 14px;
        font-weight: lighter;
      }
    }
  }
  label {
    color: $cs-grey;
    padding-top: 10px;
    margin-right: -15px;
  }
  .user-input {
    input {
      width: 100%;
      height: 40px;
      background-color: $cs-grey-lighter;
    }
  }
  .forgot-password {
    a {
      color: $cs-teal;
      text-decoration: none;
      font-weight: bold;
      font-size: 14px;
    }
    a:hover {
      color: $cs-teal-dark;
    }
  }
  .login-button {
    button {
      padding-left: 20px;
      padding-right: 20px;
      height: 35px;
      img {
        width: 20px;
      }
    }
  }

  .login-form-header-text {
    font-size: 24px;
    color: $cs-white;
    line-height: 1em;
    margin: 0.65em 0;
    font-weight: lighter;
    span {
      font-weight: bold;
    }
  }

  .login-form-header {
    background-color: #42a7c6;
  }

  .login-main-content {
    background-color: $cs-white;
    padding: 0px 15px 25px 15px;
  }

  .go-back-login-row {
    display: flex;
    justify-content: center;
  }

  .go-back-login-check {
    cursor: pointer;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    height: 26px;
    font-size: 14px;
    color: #04a74d;
    background-color: $cs-grey-lighter;
    border-radius: 100px;
    justify-content: center;
    padding: 0 40px 0 40px;
    &:hover {
      background-color: #04a74d;
      color: #fff;
    }
  }
  .back-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding-left: 4px;
    > * {
      fill: currentColor;
      width: 18px;
      height: 12px;
      color: $cs-grey-lighter;
    }
  }
}

.login-bottom-section {
  background-color: $cs-grey-light;
  padding: 30px 60px;
  width: 100%;
  bottom: 0;
  height: 200px;
  // position: absolute;
  .login-social-media {
    a {
      display: inline-block;
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border-radius: 5px;
      background-position: center;
      background-size: cover;
      background-color: $cs-white;
    }
    #linkedin {
      background-image: url("../images/cs-assets/cs-linkedin.png");
    }
    #twitter {
      background-image: url("../images/cs-assets/cs-twitter.png");
    }
    #youtube {
      background-image: url("../images/cs-assets/cs-youtube.png");
    }
    #facebook {
      background-image: url("../images/cs-assets/cs-facebook.svg");
    }
  }
  .login-links {
    margin-bottom: 60px;

    a,
    span {
      color: $cs-grey;
      text-decoration: none;
      font-size: 17px;
      margin-top: 10px;
      margin-left: 40px;
    }
    span {
      a {
        margin: 0;
      }
    }
    a:hover {
      color: $cs-grey-dark;
    }
  }
}

/* ======================================
					 &WEEK VIEW CALENDAR
========================================*/

.week-calendar {
  td:nth-child(1) {
    border: 1px solid $cs-grey-light;
  }
  .weekday-head {
    border: none !important;
    padding-top: 0 8px;
  }
}

/* ======================================
					 &POP UP STYLES
========================================*/

.popup-view {
  margin-top: 0;
  padding-top: 0;
  font-size: 13px;
  h1,
  h2,
  h3,
  h4 {
    padding-top: 0;
    margin-top: 0;
  }
  label {
    font-size: 13px;
  }
  img {
    height: 15px;
    width: 15px;
  }
  .popup-inline-select {
    a {
      color: $cs-teal;
    }
    a:hover {
      color: $cs-teal-dark;
    }
  }
  .popup-date-select {
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: center;
  }
  .textarea-container {
    textarea {
      resize: none;
      width: 100%;
      border: 2px solid $cs-grey-light;
      background-color: $cs-white;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      display: inline-block;
      min-width: 120px;
      border: 1px solid $cs-grey-light;
      background-color: $cs-grey-lightest;
      background-size: 10px;
      height: 125px;
      background-position: right 8px center;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      color: $cs-grey;
      padding-left: 10px;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .merge-editor-select {
    select {
      width: 100% !important;
    }
  }
}

/* ======================================
					 &MONTH DETAIL BARS
========================================*/

.month-details {
  font-size: 11px;
  .calendar-small {
    font-size: 11px;
    display: inline;
  }
  a.split-shift-edit {
    font-family: verdana, sans-serif;
    font-size: 11px;
    text-decoration: underline;
  }
}

.month-details b {
  margin-left: 5px;
  color: $cs-grey-darkest;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 11px !important;
}

.month-details a {
  font-weight: lighter;
  font-size: 9px;
}

.month-details b::before {
  content: " ";
  position: absolute;
  left: 5px;
  margin-left: 5px;
  margin-right: 2px;
  background-position: center;
  height: 19px;
  font-size: 9px;
  width: 5px;
  border-radius: 7px;
}

.month-detail-events b::before {
  background-image: url("../images/cs-assets/cs-pink-div-sm.svg");
}

.month-details .template-draft-events b::before {
  background-image: none;
  background-color: silver;
} 

.month-detail-events .split-shift {
  border-left: 4px solid #e279b7;
  margin-left: 4px;
}

.split-shift-delete-flag {
  color: #ce4844;
  background-color: transparent;
  border: 0;
  margin-left: 5px;
}

.month-detail-draft-events {
  border-left: 5px solid silver;
  margin-left: 10px;
  padding-left: 0px;
}

.draft-event-popover {
  z-index: 0;
}

.new-job-margin {
  margin-top: 1px;
}

.pink-left b::before {
  background-image: url("../images/cs-assets/cs-pink-div-sm.svg") !important;
}

.month-detail-requests {
  .provider-request {
    background-color: #f9f9f9;
    border: 1px dashed #bcc0c6;
    border-radius: 4px;
    padding: 4px 8px 0px;
    margin: 5px 0 5px 0;
    display: flex;
    height: auto;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    h5 {
      font-weight: bold;
      margin: 0;
    }
    p {
      text-align: center;
      margin: 0;
    }
  }
  .provider-request:hover {
    background-color: #f7faff;
    border: 1px dashed #2366b6;
    color: #2366b6;
  }
}

.month-detail-flags {
  img {
    height: 13px;
  }

  b::before {
    background-image: url("../images/cs-assets/cs-pink-div-sm.svg");
  }
}

.month-detail-unassigned-job {
  display: flex;
  justify-content: space-between;
}

.month-detail-vacations b::before {
  background-image: url("../images/cs-assets/cs-blue-div-sm.svg");
}

.month-detail-vacations > span {
  background-color: $cs-grey-lighter;
  width: 100%;
  display: block;
}

.daysoff::before {
  background-image: url("../images/cs-assets/cs-blue-div-sm.svg") !important;
}

.month-detail-daysoff b::before {
  background-image: url("../images/cs-assets/cs-blue-div-sm.svg");
}

.month-detail-daysoff::before {
  background-image: url(../images/cs-assets/cs-blue-div-sm.svg);
}

.month-detail-daysoff > span {
  background-color: $cs-grey-lighter;
  width: 100%;
  display: block;
}

.month-detail-cal_notes div {
  background-color: $cs-yellow;
  color: $cs-brown;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 100%;
  display: block;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-weight: bold;
}

.month-detail-holiday {
  text-align: center;
  font-style: italic;
  font-weight: bold;
  background: none !important;
}

.holiday-container {
  position: relative;
  bottom: 0;
}

.pink-group {
  margin-bottom: 10px;
}

.blue-group {
  position: relative;
  bottom: 0;
  margin-bottom: 5px;
}

.bottom-details-container {
  position: relative;
  bottom: 0;
}

.whiteboard-ccn-table {
  table {
    td {
      border-right: 1px solid $cs-grey-lighter;
    }
  }
}

.whiteboard-ccn-td {
  border: 1px solid $cs-grey-lighter !important;
}

.daily-archive {
  td {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* ======================================
					 Override Bootstrap
========================================*/

.btn-primary {
  color: #ffffff;
  background-color: $primary-btn-color;
  border-color: #2e6da4;
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:active:hover {
  color: #ffffff;
  background-color: darken($primary-btn-color, 10%);
  border-color: #204d74;
}

.form-horizontal .control-label.left-align {
  text-align: left;
}

a.btn {
  text-decoration: none;
}

/* ======================================
					 Override ckeditor
========================================*/

span.cke_skin_kama.cke_editor_protocol {
  padding: 0;
}

// ==================
#job-time-form select {
  width: 60px;
}

table.table tr.table-title,
table.table-striped tr.table-title {
  background-color: $cs-teal-light;
  color: white;
}

.protocol-table > tbody > tr > th,
.protocol-table > tbody > tr > td {
  vertical-align: middle;
}

#lockout-nav-bar {
  .job-nav-bar td {
    padding: 4px 0 0;
    background-color: $cs-teal;
  }
  .job-nav-bar td a {
    color: white;
    background-color: $cs-teal;
  }
}

.groups-list {
  margin: 0 auto;
  margin-bottom: 40px;
  .list-group .list-group-item {
    border: 1px solid #dddddd;
    padding: 20px;
    &.active {
      background-color: $cs-teal-lighter;
      color: white;
    }
  }
}

.select2-results__option em {
  color: #999999;
}
.select2-results__option--highlighted em {
  color: white;
}

.provider-staff-level-row {
  margin: 10px 0;
}
.provider-staff-level-row:not(:first-child) {
  padding-top: 20px;
  border-top: 1px solid #edf0f4;
}

.additional-assignments {
  margin: 0;
  padding: 0px 0px 0px 7px;

  ul {
    display: flex;
    margin: 0 auto;
    list-style: none;
    padding-inline-start: 0px;
  }
  li {
    list-style: none;
  }

  li::before {
    content: "";
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    background-color: blue;
    border-radius: 50%;
    margin-right: 0.5em;
  }
  li.less-ideal::before {
    background-color: grey;
  }
  li.is-ideal::before {
    background-color: blue;
  }
  li.over-ideal::before {
    background-color: red;
  }
}
.mdv-v2 {
  &.checked {
    display: block;
  }

  .mdv-v2-settings {
    article {
      font-size: 14px;
    }
    h3 {
      font-weight: bold;
    }
    label,
    h3 {
      padding-left: 30px;
    }
    .provider-contact-settings,
    .default-assignment-sort {
      label {
        padding-left: 60px;
        font-weight: normal;
      }
    }
  }
}
